import { ExpandMore } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AppAvatar from 'components/new_components/AppAvatar';
// redux
import UserAvatar from 'assets/img/userAvatar.svg';
import CustomTooltip from 'components/new_components/CustomTooltip';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { GetDownloadLink } from 'store/actions/generalActions';

const UserDropdownTrigger = (props) => {
    const { showDropdown, user_info } = props;
    const [imageLink, setImageLink] = useState('');
    const theme = useTheme();
    // const userType = useMemo(() => getType('type'), []);
    useEffect(() => {
        const user_type =
            user_info.user_type === 'merchant'
                ? 'merchant'
                : user_info.user_type === 'employee'
                ? 'employee'
                : user_info.user_type === 'support'
                ? 'user'
                : user_info.user_type === 'admin'
                ? 'admin'
                : user_info.user_type === 'implementer'
                ? 'implementer'
                : 'auditor';

        // set loading icon on start of fetch request
        GetDownloadLink('user_mgt', user_type, user_info.organization.id)().then((res) => {
            if (res.success) {
                setImageLink(res.data);
            } else {
                setImageLink('');
            }
        });
    }, [user_info?.picture]);
    return (
        <Box
            component="button"
            onClick={showDropdown}
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                width: 'fit-content',
                border: 'none',
                backgroundColor: 'inherit',
                color: theme.palette.primary[900],
            }}
        >
            <AppAvatar src={imageLink || UserAvatar} size={25} />
            <CustomTooltip title="More Options" placement="bottom">
                <ExpandMore color="primary" />
            </CustomTooltip>
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        company_info: state?.generalReducers?.user_info?.organization,
        employee_details: state?.generalReducers?.user_info?.employee?.user_detail,
        user_info: state?.generalReducers?.user_info,
    };
};
export default connect(mapStateToProps, {})(UserDropdownTrigger);
