import React from 'react';
import { FormHelperText } from '@mui/material';
// formik
import { useFormikContext } from 'formik';
// core components
import AppInput from '../AppInput';

import { formatSentence } from 'utils';

const AppFormInput = (props) => {
    const { name, regex, multiline, adornment, onChange, maxLength, ...restProps } = props;

    // get the formik context values
    const { setFieldTouched, handleChange, touched, values, errors } = useFormikContext();

    const handleInputChange = (e) => {
        if (maxLength && !isNaN(maxLength)) {
            e.target.value = e.target.value.slice(0, maxLength);
        }

        if (!regex || regex?.test(e.target.value)) {
            handleChange(name)(e);
            setTimeout(() => setFieldTouched(name), 400);
        }

        onChange && onChange(e.target.value);
    };

    // Ensure `values` and `name` are defined before accessing `values[name]`
    const fieldValue = values && name in values ? values[name] : '';

    // Prevent scrolling from affecting the value of number input
    const handleWheel = (e) => {
        if (e.target.type === 'number') {
            e.preventDefault();
            e.target.blur(); // Temporarily remove focus to prevent the value from changing
            setTimeout(() => e.target.focus(), 0); // Refocus after preventing the scroll
        }
    };

    return (
        <>
            <AppInput
                value={fieldValue}
                onChange={handleInputChange}
                onBlur={() => setFieldTouched(name)}
                error={touched[name] && errors[name]}
                onWheel={handleWheel}
                labelStyle={{
                    color: '#64748B',
                    fontSize: '13px',
                    fontWeight: 500,
                }}
                multiline={multiline}
                adornment={adornment}
                {...restProps}
            />
            {touched[name] && errors[name] && <FormHelperText error>{formatSentence(errors[name])}</FormHelperText>}
        </>
    );
};

export default AppFormInput;
