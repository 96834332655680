import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

//core components
import { Box, List, ListItem, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AppReportModal from 'components/new_components/AppReportModal';
import LoadingState from 'components/new_components/LoadingState';
import closeIcon from '../../../assets/img/closeModal.svg';
import bulb from '../../../assets/img/lightBulb.svg';
import pdfIcon from '../../../assets/img/pdf.svg';

//redux
import { connect } from 'react-redux';
import { GetMerchantAsvscanReportById } from 'store/actions/merchantActions';
import { formatDateObjectHandler } from 'utils';

//translations
import { useTranslation } from 'react-i18next';
import { GetDownloadLink } from 'store/actions/generalActions';

const PCIReportModal = (props) => {
    //props
    const { open, handleClose, title, GetMerchantAsvscanReportById, report, asv_reports_by_id } = props;

    //state
    const [getReportsLoading, setGetReportLoading] = useState(false);

    //theme
    const theme = useTheme();

    //translation
    const { t } = useTranslation('pciscans');

    const getScanReports = async (report) => {
        setGetReportLoading(true);
        const res = await GetMerchantAsvscanReportById(report?.id);
        setGetReportLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (report) {
            getScanReports(report);
        }
    }, [report]);

    return (
        <div>
            <AppReportModal open={open} handleClose={handleClose} width={511} borderRadius={1} height="100%">
                <div className="flex items-end justify-between h-[52px] mb-[24px] ml-[24px] mr-[14px]">
                    <h1 className="text-[18px] font-semibold text-[#202D66]">{title}</h1>
                    <div
                        onClick={handleClose}
                        className="h-[24px] w-[24px] rounded-full border-[1px] border-solid  border-[#E2E8F0] flex items-center justify-center"
                    >
                        <img src={closeIcon} alt="close icon" className="cursor-pointer" />
                    </div>
                </div>
                <Stack
                    flexDirection="row"
                    justifyContent="flex-start"
                    sx={{
                        py: '8px',
                        px: '16px',
                        fontSize: 13,
                        fontWeight: 400,
                        color: '#64748B',
                        background: '#F8FAFC',
                        borderRadius: '4px',
                        ml: '24px',
                        mr: '14px',
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'flex-start',
                        border: '1px solid #F0F0FA',
                    }}
                >
                    <img src={bulb} alt="bulb" />
                    <span>
                        <span className="text-[#202D66] text-[13px]">{t('pciReportModal.subtitle1')}</span> gives you a
                        brief summary of your PCI scan while{' '}
                        <span className="text-[#202D66] text-[13px]"> {t('pciReportModal.subtitle3')} </span> gives a
                        full overview of scan carried out
                    </span>
                </Stack>
                <List sx={{ paddingTop: 0, mb: 3 }}>
                    {getReportsLoading ? (
                        <LoadingState />
                    ) : (
                        asv_reports_by_id
                            ?.sort((a, b) => b?.id - a?.id)
                            ?.map((report) => <Report key={report?.id} report={report} theme={theme} t={t} />)
                    )}
                </List>
            </AppReportModal>
        </div>
    );
};

function Report({ report, theme, t }) {
    const [executiveLoadingLink, setExecutiveLoadingLink] = useState(false);
    const [detailedLoadingLink, setDetailedLoadingLink] = useState(false);

    const getDownloadLink = async (type) => {
        if (report[type]) {
            type === 'detailed'
                ? setDetailedLoadingLink(true)
                : type === 'executive'
                ? setExecutiveLoadingLink(true)
                : null;
            const res = await GetDownloadLink('asvc_requests', 'asv_report', report.id, true)();
            type === 'detailed'
                ? setDetailedLoadingLink(false)
                : type === 'executive'
                ? setExecutiveLoadingLink(false)
                : null;
            if (res?.success) {
                const approvedUrl = res?.data?.find((item) => item[type]);

                // open decoded url
                window.open(approvedUrl[type], '_blank');
            } else {
                toast.error('Something went wrong!');
                // open original file url
                window.open(report[type], '_blank');
            }
        }
    };

    return (
        <div className="-mb-[80px]">
            <div
                key={report?.id}
                className="flex items-center justify-between w-full mt-[16px] mb-[24px] ml-[24px] mr-[14px]"
            >
                <div className="flex gap-[8px] items-center ml-[10px]">
                    <img src={pdfIcon} alt="file icon" />
                    <div className="flex flex-col items-start">
                        <span className="text-[#475569] text-[15px]">{`${report?.name} ${report?.version}`}</span>
                        <span className="text-[#94A3B8] text-[12px]">
                            {' '}
                            {formatDateObjectHandler(report?.date_created, 'MMMM Do, YYYY')}
                        </span>
                    </div>
                </div>
                <div className="py-[4px] px-[8px] mr-[48px] border-[1px] border-solid border-[#E1E2EC] rounded-[4px] flex items-center gap-[4px]">
                    <div
                        className="w-[8px] h-[8px] rounded-full"
                        style={{
                            background: report?.status ? '#55BE8B' : '#F43F5E',
                        }}
                    ></div>
                    <span className="text-[10px] font-medium text-[#64748B]">
                        {report?.status ? t('pciReportModal.passed') : t('pciReportModal.failed')}
                    </span>
                </div>
            </div>
            <ListItem sx={{ alignItems: 'flex-start' }}>
                <div
                    key={report?.id}
                    style={{ oveflow: 'hidden' }}
                    className="flex items-center justify-center w-[511px] gap-[8px] bg-[#F8FAFC] h-[84px]  -ml-[28px] -mr-[60px] relative z-10"
                >
                    <div className="flex items-center justify-center gap-[8px]">
                        <Stack direction="row">
                            <Box
                                onClick={() => !executiveLoadingLink && getDownloadLink('executive')}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 6,
                                }}
                                sx={{
                                    color: '#334155',
                                    border: `1px solid #E2E8F0`,
                                    width: 'fit-content',
                                    height: 36,
                                    px: 1.5,
                                    py: 0.6,
                                    fontSize: 14,
                                    fontWeight: 500,
                                    borderRadius: '2px',
                                    textDecoration: 'none',
                                    background: '#FFFFFF',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        color: theme.palette.neutral[50],
                                        textDecoration: 'none',
                                    },
                                }}
                            >
                                {executiveLoadingLink ? <LoadingState size={14} styles={{ width: 'fit' }} /> : null}
                                {t('pciReportModal.executive')}
                            </Box>
                            {report?.detailed && (
                                <Box
                                    onClick={() => !detailedLoadingLink && getDownloadLink('detailed')}
                                    style={{
                                        color: '#FFFFFF',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 6,
                                    }}
                                    sx={{
                                        color: '#FFFFFF',
                                        width: 'fit-content',
                                        px: 1.5,
                                        py: 0.6,
                                        ml: 1,
                                        fontSize: 14,
                                        fontWeight: 600,
                                        borderRadius: '2px',
                                        textDecoration: 'none',
                                        background: '#202D66',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            color: theme.palette.neutral[50],
                                            textDecoration: 'none',
                                        },
                                    }}
                                >
                                    {detailedLoadingLink ? <LoadingState size={14} styles={{ width: 'fit' }} /> : null}
                                    {t('pciReportModal.detailed')}
                                </Box>
                            )}
                        </Stack>
                    </div>
                </div>
            </ListItem>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        asv_reports_by_id: state?.merchantReducers?.asv_reports_by_id,
    };
};
export default connect(mapStateToProps, { GetMerchantAsvscanReportById })(PCIReportModal);
