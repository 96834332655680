/* eslint-disable no-unused-vars */

import { TrendingUpRounded } from '@mui/icons-material';
import { Dialog, Snackbar } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GetAllMerchantUsers } from 'store/actions/generalActions';
import { GetRiskSettings, SetRiskDefaultSettings } from 'store/actions/riskAssessmentActions';
import https from '../../../../../utils/https';
import RiskAssessmentSettingsGeneralTab from './components/General';
import RiskAssessmentSettingsRiskMatrixTab from './components/RiskMatrix';
import RiskAssessmentSettingsRiskTemplatesTab from './components/RiskTemplates';

const RiskAssessmentSettingsTabs = {
    general: 'General',
    riskMatrix: 'Risk Matrix',
    riskTemplates: 'Risk Templates',
};

export default function RiskAssessmentSettings({ location }) {
    const [currentTab, setCurrentTab] = useState(RiskAssessmentSettingsTabs.general);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingDefault, setLoadinDefault] = useState(false);
    const [isResetRiskModalOpen, setIsResetRiskModalOpen] = useState(false);
    const [riskGeneralTabStates, setRiskGeneralTabStates] = useState({
        riskLevel: 25,
        managementApproval: false,
        managementApprovers: [],
        userIDs: [],
        peopleIDs: [],
        by3Level: 'low',
        by5Level: 'low',
        riskAppetite: {
            matrixThree: {
                low: ['accept', 'avoid', 'transfer'],
                medium: ['mitigate', 'accept', 'avoid', 'transfer'],
                high: ['mitigate'],
            },
            matrixFive: {
                low: ['accept', 'avoid', 'transfer'],
                medium: ['mitigate', 'accept', 'avoid', 'transfer'],
                high: ['mitigate'],
            },
        },
    });

    // Redux
    const dispatch = useDispatch();
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);
    const get_risk_settings = useSelector((state) => state?.riskAssessmentReducers?.get_risk_settings);
    const all_merchant_users = useSelector((state) => state?.generalReducers?.all_merchant_users);
    // console.log({ get_risk_settings });

    const getRiskSettings = async () => {
        setLoading(true);
        const res = await dispatch(GetRiskSettings());
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    // risk-assessment/settings

    const getAllMerchantUsers = async () => {
        setLoading(true);
        const res = await dispatch(GetAllMerchantUsers());
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        getRiskSettings();
        getAllMerchantUsers();
    }, []);

    const [riskMatrixTabStates, setRiskMatrixTabStates] = useState({
        risk5x5Matrix: {
            columnLabels: {
                0: 'Negligible',
                1: 'Minor',
                2: 'Moderate',
                3: 'Major',
                4: 'Critical',
            },
            rowLabels: {
                0: 'Rare',
                1: 'Unlikely',
                2: 'Possible',
                3: 'Likely',
                4: 'Almost Certain',
            },
            data: {
                0: 'very_low',
                1: 'very_low',
                2: 'very_low',
                3: 'very_low',
                4: 'low',
                5: 'very_low',
                6: 'very_low',
                7: 'low',
                8: 'low',
                9: 'medium',
                10: 'very_low',
                11: 'low',
                12: 'low',
                13: 'medium',
                14: 'high',
                15: 'very_low',
                16: 'low',
                17: 'medium',
                18: 'high',
                19: 'very_high',
                20: 'low',
                21: 'medium',
                22: 'high',
                23: 'very_high',
                24: 'very_high',
            },
        },
        risk3x3Matrix: {
            columnLabels: {
                0: 'Minor',
                1: 'Moderate',
                2: 'Critical',
            },
            rowLabels: {
                0: 'Rare',
                1: 'Possible',
                2: 'Almost Certain',
            },
            data: {
                0: 'low',
                1: 'low',
                2: 'low',
                3: 'low',
                4: 'medium',
                5: 'medium',
                6: 'low',
                7: 'medium',
                8: 'high',
            },
        },
    });
    const [localManagementApprovers, setLocalManagementApprovers] = useState([]);

    const history = useHistory();

    const userDetails = useSelector((state) => state?.generalReducers?.user_info);

    const queryClient = useQueryClient();

    //function
    const setRiskDefaultSettings = async () => {
        setLoadinDefault(TrendingUpRounded);
        const res = await dispatch(
            SetRiskDefaultSettings(
                {
                    default_settings: true,
                },
                get_risk_settings?.[0]?.id
            )
        );
        setLoadinDefault(false);
        if (res?.success) {
            toast.success('Reset to default Successfully');
            getRiskSettings();
            setIsResetRiskModalOpen(false);
        } else {
            toast.error(res?.message);
        }
    };

    const setRiskDefaultSettingsMutation = useMutation({
        mutationFn: setRiskDefaultSettings,
        onSuccess: () => {
            setIsResetRiskModalOpen(false);
            setIsSnackbarOpen(true);
        },
        onError: (error) => {
            toast.error(error?.message);
        },
    });

    const updateCurrentUserRiskManagementDataMutation = useMutation({
        mutationFn: updateCurrentUserRiskManagementData,
        onSuccess: () => {
            queryClient.invalidateQueries('userDetails');
            history.push('/merchant/assessment/risk-asessement');
        },
        onError: (error) => {
            toast.error(error?.message);
            history.push('/merchant/assessment/risk-asessement');
        },
    });

    const isLoading = loading || updateCurrentUserRiskManagementDataMutation.isLoading;

    function RenderActiveTab() {
        switch (currentTab) {
            case RiskAssessmentSettingsTabs.general:
                return (
                    <RiskAssessmentSettingsGeneralTab
                        riskGeneralTabStates={riskGeneralTabStates}
                        setRiskGeneralTabStates={setRiskGeneralTabStates}
                        localManagementApprovers={localManagementApprovers}
                        setLocalManagementApprovers={setLocalManagementApprovers}
                    />
                );
            case RiskAssessmentSettingsTabs.riskMatrix:
                return (
                    <RiskAssessmentSettingsRiskMatrixTab
                        riskMatrixTabStates={riskMatrixTabStates}
                        setRiskMatrixTabStates={setRiskMatrixTabStates}
                    />
                );
            case RiskAssessmentSettingsTabs.riskTemplates:
                return <RiskAssessmentSettingsRiskTemplatesTab />;
            default:
                return (
                    <RiskAssessmentSettingsGeneralTab
                        riskGeneralTabStates={riskGeneralTabStates}
                        setRiskGeneralTabStates={setRiskGeneralTabStates}
                        localManagementApprovers={localManagementApprovers}
                        setLocalManagementApprovers={setLocalManagementApprovers}
                    />
                );
        }
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setIsSnackbarOpen(false);
    }

    function saveRiskManagementSettings() {
        const newData = {
            tag: 'custom',
            target_risk_level: riskGeneralTabStates.riskLevel,
            risk_plan_3x3: {
                low: riskGeneralTabStates?.riskAppetite.matrixThree?.low,
                medium: riskGeneralTabStates?.riskAppetite.matrixThree?.medium,
                high: riskGeneralTabStates?.riskAppetite.matrixThree?.high,
            },
            risk_plan_5x5: {
                low: riskGeneralTabStates?.riskAppetite.matrixFive?.low,
                medium: riskGeneralTabStates?.riskAppetite.matrixFive?.medium,
                high: riskGeneralTabStates?.riskAppetite.matrixThree?.high,
            },
            matrix_3x3: riskMatrixTabStates.risk3x3Matrix.data,
            matrix_5x5: riskMatrixTabStates.risk5x5Matrix.data,
            rows_3x3: riskMatrixTabStates.risk3x3Matrix.rowLabels,
            columns_3x3: riskMatrixTabStates.risk3x3Matrix.columnLabels,
            rows_5x5: riskMatrixTabStates.risk5x5Matrix.rowLabels,
            columns_5x5: riskMatrixTabStates.risk5x5Matrix.columnLabels,
            user_ids: riskGeneralTabStates.userIDs,
            people_ids: riskGeneralTabStates.peopleIDs,
            mgt_approval: riskGeneralTabStates.managementApproval,
            merchant: userDetails?.organization?.id,
            risk_approach: riskGeneralTabStates?.risk_approach,
        };

        const updatedData = {
            target_risk_level: riskGeneralTabStates.riskLevel,
            risk_plan_3x3: {
                low: riskGeneralTabStates?.riskAppetite.matrixThree?.low,
                medium: riskGeneralTabStates?.riskAppetite.matrixThree?.medium,
                high: riskGeneralTabStates?.riskAppetite.matrixThree?.high,
            },
            risk_plan_5x5: {
                low: riskGeneralTabStates?.riskAppetite.matrixFive?.low,
                medium: riskGeneralTabStates?.riskAppetite.matrixFive?.medium,
                high: riskGeneralTabStates?.riskAppetite.matrixThree?.high,
            },
            matrix_3x3: riskMatrixTabStates.risk3x3Matrix.data,
            matrix_5x5: riskMatrixTabStates.risk5x5Matrix.data,
            rows_3x3: riskMatrixTabStates.risk3x3Matrix.rowLabels,
            columns_3x3: riskMatrixTabStates.risk3x3Matrix.columnLabels,
            rows_5x5: riskMatrixTabStates.risk5x5Matrix.rowLabels,
            columns_5x5: riskMatrixTabStates.risk5x5Matrix.columnLabels,
            user_ids: riskGeneralTabStates.userIDs.length ? riskGeneralTabStates.userIDs : ['None'],
            people_ids: riskGeneralTabStates.peopleIDs.length ? riskGeneralTabStates.peopleIDs : ['None'],
            mgt_approval: riskGeneralTabStates.managementApproval,
            risk_approach: riskGeneralTabStates?.risk_approach,
            merchant: userDetails?.organization?.id,
        };

        updateCurrentUserRiskManagementDataMutation.mutate({
            riskAssessment: merchant_info?.risk_assessment,
            riskDataID: get_risk_settings[0]?.id,
            data: merchant_info?.risk_assessment ? updatedData : newData,
        });
    }
    useEffect(() => {
        if (get_risk_settings?.length) {
            const currentUserRiskData = get_risk_settings[0];

            setRiskGeneralTabStates((prevState) => ({
                ...prevState,
                riskLevel: currentUserRiskData?.target_risk_level,
                risk_approach: currentUserRiskData?.risk_approach,
                managementApproval: currentUserRiskData?.mgt_approval,
                managementApprovers: currentUserRiskData?.approvers_ids,
                riskAppetite: {
                    matrixThree: {
                        low: currentUserRiskData?.risk_plan_3x3?.low?.map((item) => item.toLowerCase()),
                        medium: currentUserRiskData?.risk_plan_3x3?.medium?.map((item) => item.toLowerCase()),
                        high: currentUserRiskData?.risk_plan_3x3?.high?.map((item) => item.toLowerCase()),
                    },
                    matrixFive: {
                        low: currentUserRiskData?.risk_plan_5x5?.low?.map((item) => item.toLowerCase()),
                        medium: currentUserRiskData?.risk_plan_5x5?.medium?.map((item) => item.toLowerCase()),
                        high: currentUserRiskData?.risk_plan_5x5?.high?.map((item) => item.toLowerCase()),
                    },
                },
            }));

            setRiskMatrixTabStates((prevState) => ({
                ...prevState,
                risk5x5Matrix: {
                    ...prevState.risk5x5Matrix,
                    columnLabels: currentUserRiskData?.columns_5x5,
                    rowLabels: currentUserRiskData?.rows_5x5,
                    data: currentUserRiskData?.matrix_5x5,
                },
                risk3x3Matrix: {
                    ...prevState.risk3x3Matrix,
                    columnLabels: currentUserRiskData?.columns_3x3,
                    rowLabels: currentUserRiskData?.rows_3x3,
                    data: currentUserRiskData?.matrix_3x3,
                },
            }));
        }
    }, [get_risk_settings]);

    useEffect(() => {
        if (all_merchant_users) {
            setRiskGeneralTabStates((prevState) => ({
                ...prevState,
                userIDs: all_merchant_users?.users
                    ?.map((item) => item.id)
                    .filter((item) => riskGeneralTabStates.managementApprovers.includes(item)),
                peopleIDs: all_merchant_users.people
                    ?.map((item) => item.id)
                    .filter((item) => riskGeneralTabStates.managementApprovers.includes(item)),
            }));
        }
    }, [all_merchant_users]);

    useEffect(() => {
        setLocalManagementApprovers(riskGeneralTabStates.managementApprovers);
    }, [riskGeneralTabStates.managementApprovers]);

    useEffect(() => {
        if (location.search === '?tab=templates') {
            setCurrentTab(RiskAssessmentSettingsTabs.riskTemplates);

            history.replace('/risk-assessment/settings');
        }
    }, [location]);

    return (
        <>
            <div className="flex items-center gap-2 bg-white border-b border-[#F1F5F9] !py-2 px-6">
                <img
                    src="/img/risk-assessment/arrow-left-icon.svg"
                    alt="Left Arrow Icon"
                    className="object-contain cursor-pointer hover:scale-90 transition-transform"
                    onClick={saveRiskManagementSettings}
                />

                <h3 className="text-[#002C72] font-bold text-lg">Risk management settings</h3>
            </div>

            <div className="flex items-center justify-between bg-white !px-6 border-b border-[#F1F5F9]">
                <div className="flex items-center gap-6">
                    <button
                        className={`relative text-sm bg-transparent !p-0 !py-3 before:absolute before:bottom-0 before:left-1/2 before:h-[3px] before:-translate-x-1/2 before:bg-[#202D66] before:transition-all before:duration-500 hover:bg-transparent hover:text-[#202D66] ${
                            currentTab === RiskAssessmentSettingsTabs.general
                                ? 'font-semibold text-[#202D66] before:w-full'
                                : 'text-[#64748B] font-medium before:w-0'
                        }`}
                        onClick={() => setCurrentTab(RiskAssessmentSettingsTabs.general)}
                    >
                        <span className="capitalize">{RiskAssessmentSettingsTabs.general}</span>
                    </button>

                    <button
                        className={`relative text-sm bg-transparent !p-0 !py-3 before:absolute before:bottom-0 before:left-1/2 before:h-[3px] before:-translate-x-1/2 before:bg-[#202D66] before:transition-all before:duration-500 hover:bg-transparent hover:text-[#202D66] ${
                            currentTab === RiskAssessmentSettingsTabs.riskMatrix
                                ? 'font-semibold text-[#202D66] before:w-full'
                                : 'text-[#64748B] font-medium before:w-0'
                        }`}
                        onClick={() => setCurrentTab(RiskAssessmentSettingsTabs.riskMatrix)}
                    >
                        <span className="capitalize">{RiskAssessmentSettingsTabs.riskMatrix}</span>
                    </button>

                    <button
                        className={`relative text-sm bg-transparent !p-0 !py-3 before:absolute before:bottom-0 before:left-1/2 before:h-[3px] before:-translate-x-1/2 before:bg-[#202D66] before:transition-all before:duration-500 hover:bg-transparent hover:text-[#202D66] ${
                            currentTab === RiskAssessmentSettingsTabs.riskTemplates
                                ? 'font-semibold text-[#202D66] before:w-full'
                                : 'text-[#64748B] font-medium before:w-0'
                        }`}
                        onClick={() => setCurrentTab(RiskAssessmentSettingsTabs.riskTemplates)}
                    >
                        <span className="capitalize">{RiskAssessmentSettingsTabs.riskTemplates}</span>
                    </button>
                </div>

                {merchant_info?.risk_assessment && (
                    <div>
                        <button
                            className="!bg-[#F8FAFC] text-[#395BA9] !p-2 rounded font-semibold text-[13px] border !border-[#E2E8F0] transition-all hover:brightness-95"
                            onClick={() => setIsResetRiskModalOpen(true)}
                        >
                            Reset to default
                        </button>
                    </div>
                )}
            </div>

            <div className="!py-6">
                <RenderActiveTab />
            </div>

            <Dialog
                open={isLoading}
                PaperProps={{
                    sx: {
                        borderRadius: '8px !important',
                        padding: 0,
                        boxShadow: '0px 16px 24px 0px #00000026',
                        width: '413px',
                    },
                }}
            >
                <div className="flex items-center justify-center flex-col gap-2 py-10">
                    <Loader2 className="h-10 w-10 animate-spin" />

                    <p className="text-[#64748B] text-sm">Proccessing...</p>
                </div>
            </Dialog>

            <Dialog
                open={isResetRiskModalOpen}
                onClose={() => setIsResetRiskModalOpen(false)}
                PaperProps={{
                    sx: {
                        borderRadius: '2px !important',
                        padding: 0,
                        boxShadow: '0px 16px 24px 0px #00000026',
                        width: '440px',
                    },
                }}
            >
                <div className="flex flex-col gap-2 items-center text-center bg-white !p-6">
                    <h3 className="text-[#475569] text-lg font-semibold">Reset to default</h3>

                    <p className="text-[#64748B] font-normal text-[13px] leading-5">
                        Risk management settings (except templates) will revert to default. Are you sure you want to
                        proceed?
                    </p>
                </div>

                <div className="flex items-center justify-center !p-6 bg-[#F8FAFC] gap-2">
                    <button
                        className="bg-[#fff] border border-[#E2E8F0] rounded-sm !px-4 !py-2 hover:bg-red-500 hover:text-white transition-colors text-[#334155] text-sm font-medium"
                        onClick={() => setIsResetRiskModalOpen(false)}
                    >
                        Cancel
                    </button>

                    <button
                        className="bg-[#395BA9] rounded-sm !px-4 !py-2 hover:bg-[#151e44] transition-all text-white text-sm font-medium disabled:opacity-50 disabled:pointer-events-none flex items-center"
                        disabled={loading}
                        onClick={setRiskDefaultSettings}
                    >
                        {setRiskDefaultSettingsMutation.isLoading && <Loader2 className="mr-2 animate-spin h-4 w-4" />}
                        Yes, reseet
                    </button>
                </div>
            </Dialog>

            <Snackbar
                open={isSnackbarOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                autoHideDuration={10000}
                onClose={handleSnackbarClose}
                sx={{
                    '& .MuiSnackbarContent-root': {
                        borderRadius: '4px',
                        padding: '0px',
                        minWidth: 'auto !important',
                        boxShadow: '0px 4px 6px 0px #3D6BA31A',
                    },
                    '& .MuiSnackbarContent-message': {
                        padding: '0px',
                    },
                }}
                message={
                    <div className="bg-[#E8FFEE] !py-3 !px-2 flex items-start gap-[15px]">
                        <div className="flex items-start gap-2 max-w-[278px]">
                            <div>
                                <img
                                    src="/img/risk-assessment/check-square-broken.svg"
                                    alt="Checkmark Icon"
                                    className="object-contain scale-110 mt-1"
                                />
                            </div>

                            <p className="text-[#37A372] text-sm font-medium">
                                Risk configuration successfully updated.
                            </p>
                        </div>

                        <button className="transition-transform hover:scale-75" onClick={handleSnackbarClose}>
                            <img
                                src="/img/risk-assessment/close-circle.svg"
                                alt="Close Popup Icon"
                                className="object-contain cursor-pointer"
                            />
                        </button>
                    </div>
                }
            />
        </>
    );
}

async function updateCurrentUserRiskManagementData({ riskAssessment, riskDataID, data }) {
    if (riskAssessment) {
        const response = await https().patch(`/scans/risk_mgt_settings/${riskDataID}/`, data);
        return response.data;
    } else {
        const response = await https().post(`/scans/risk_mgt_settings/`, data);
        return response.data;
    }
}
