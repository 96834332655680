/* eslint-disable no-unused-vars */
import { Drawer, Slide } from '@mui/material';
import CircularLoader from 'components/Loaders/CircularLoader';
import { forwardRef } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

function DrawerSuspense({ open = true, title = '' }) {
    return (
        <Drawer
            open={open}
            anchor="right"
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={() => {}}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: 370,
                    width: 535,
                    flex: 1,
                    overflow: 'auto',
                    zIndex: 9999,
                },
            }}
            scroll="paper"
        >
            <div className="flex flex-col gap-4">
                <div className="border-b border-[#F1F5F9] px-2 !py-4 flex items-center gap-2">
                    <h3 className="pl-2 text-[#202D66] capitalize font-semibold text-base">{title}</h3>
                </div>

                <CircularLoader />
            </div>
        </Drawer>
    );
}

export default DrawerSuspense;
