import { Menu, MenuItem } from '@mui/material';
import CustomTooltip from 'components/new_components/CustomTooltip';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const AcceptForm = ({
    riskAccept,
    setRiskAccept,
    residualLikelihoodOrImpact,
    error,
    setError,
    residual_impact,
    setResidualImpact,
    residual_likelihood,
    setResidualLikelihood,
    is_5x5,
}) => {
    // states
    const [localStates, setLocalStates] = useState({
        justification: riskAccept?.justification,
        contingency_plan_text: riskAccept?.contingency_plan_text,
        count: riskAccept?.contingency_timeline?.count,
        time: riskAccept?.contingency_timeline?.time,
    });
    const [assignToAnchorEl, setAssignToAnchorEl] = useState(null);
    const [residualLikelihoodAnchorEl, setResidualLikelihoodAnchorEl] = useState(null);
    const [search, setSearch] = useState('');
    const [residualImpactAnchorEl, setResidualImpactAnchorEl] = useState(null);
    const [firstReviewDataAnchorEl, setFirstReviewDataAnchorEl] = useState(null);

    // redux
    const { all_merchant_users } = useSelector((state) => state.riskAssessmentReducers);
    const [risk_settings] = useSelector((state) => state?.riskAssessmentReducers?.get_risk_settings || []);

    const all_users = all_merchant_users?.people?.concat(all_merchant_users?.users);
    const filtered_employees = all_users?.filter((user) => user?.email.toLowerCase().includes(search.toLowerCase()));
    return (
        <>
            <div className="flex flex-col gap-2">
                <p className="font-medium text-sm text-[#64748B]">Provide justification</p>

                <textarea
                    placeholder="Enter justification"
                    value={localStates.justification}
                    onChange={(event) => {
                        setLocalStates((prev) => ({
                            ...prev,
                            justification: event.target.value,
                        }));
                        setError(false);
                    }}
                    onBlur={(event) => {
                        setRiskAccept((prev) => ({
                            ...prev,
                            justification: event.target.value,
                        }));
                        setError(false);
                    }}
                    className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 !min-h-[76px] !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                />
                {error && localStates?.justification === '' && (
                    <span className="text-xs text-[#FF0000] font-normal mt-2">Justification is required</span>
                )}
            </div>

            <button
                className="flex items-center gap-2 transition-all hover:brightness-90"
                onClick={() => {
                    setRiskAccept((prev) => ({
                        ...prev,
                        contingency_plan: !prev?.contingency_plan,
                    }));
                    setError(false);
                }}
            >
                {riskAccept?.contingency_plan ? (
                    <img
                        src="/img/risk-assessment/checkbox-buuton-checked.svg"
                        alt="Checkbox icon"
                        className="object-contain"
                    />
                ) : (
                    <img
                        src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                        alt="Checkbox icon"
                        className="object-contain"
                    />
                )}

                <p className="font-medium text-sm text-[#395BA9]">Include contingency plan</p>
            </button>

            <div
                className="flex flex-col gap-4 !border-y !border-[#CBD5E1] !py-4 !px-6"
                hidden={!riskAccept?.contingency_plan}
            >
                <h5 className="text-[#395BA9] font-semibold text-base">Contingency plan</h5>

                <div className="flex flex-col gap-2">
                    <p className="font-medium text-sm text-[#64748B]">Develop contingency plan</p>

                    <textarea
                        placeholder="Enter Contingency plan"
                        value={localStates.plan}
                        onChange={(event) => {
                            setLocalStates((prev) => ({
                                ...prev,
                                contingency_plan_text: event.target.value,
                            }));
                        }}
                        onBlur={(event) =>
                            setRiskAccept((prev) => ({
                                ...prev,
                                contingency_plan_text: event.target.value,
                            }))
                        }
                        className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 !min-h-[76px] !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                    />
                    {error && localStates?.contingency_plan_text === '' && (
                        <span className="text-xs text-[#FF0000] font-normal mt-2">Contigency plan is required</span>
                    )}
                </div>

                <div className="flex flex-col gap-2">
                    <p className="font-medium text-sm text-[#64748B] mb-1">Mitigation within (timeline)</p>
                    <div className="flex gap-4">
                        <div className="flex-1">
                            <div className="flex justify-between items-center !border !border-[#E2E8F0] pl-1 pr-3 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75">
                                <input
                                    type="number"
                                    inputMode="numeric"
                                    placeholder="Enter timeline"
                                    value={localStates?.count}
                                    onChange={(event) => {
                                        setLocalStates((prev) => ({
                                            ...prev,
                                            count: event.target.value,
                                        }));
                                    }}
                                    onBlur={(event) =>
                                        setRiskAccept((prev) => ({
                                            ...prev,
                                            contingency_timeline_timeline: {
                                                ...prev.contingency_timeline_timeline,
                                                count: event.target.value,
                                            },
                                        }))
                                    }
                                    min={0}
                                    max={100}
                                    step={1}
                                    className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none outline-none bg-transparent !rounded-sm !p-2 h-9 border-none placeholder:opacity-95"
                                />
                                <div className="flex flex-col gap-0.5 justify-center items-center">
                                    <img
                                        src="/img/risk-assessment/arrow-up-icon.png"
                                        alt="Arrow Up Icon"
                                        className="object-contain hover:no-underline hover:scale-90 transition-transform cursor-pointer aria-disabled:opacity-50 aria-disabled:pointer-events-none"
                                        aria-disabled={localStates.count === 100}
                                        onClick={() =>
                                            setLocalStates((prev) => ({
                                                ...prev,
                                                count: localStates.count + 1,
                                            }))
                                        }
                                    />

                                    <img
                                        src="/img/risk-assessment/arrow-down-icon.png"
                                        alt="Arrow Down Icon"
                                        className="object-contain hover:no-underline hover:scale-90 transition-transform cursor-pointer aria-disabled:opacity-50 aria-disabled:pointer-events-none"
                                        aria-disabled={localStates.count === 0}
                                        onClick={() =>
                                            setLocalStates((prev) => ({
                                                ...prev,
                                                count: localStates.count - 1,
                                            }))
                                        }
                                    />
                                </div>
                            </div>
                            {error && localStates?.count === 0 && (
                                <span className="text-xs text-[#FF0000] font-normal mt-2">Count is required</span>
                            )}
                        </div>
                        <div className="flex-1">
                            <button
                                aria-controls={firstReviewDataAnchorEl ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={firstReviewDataAnchorEl ? 'true' : undefined}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setFirstReviewDataAnchorEl(event.currentTarget);
                                }}
                                className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                            >
                                <p
                                    className={`font-normal text-sm ${
                                        localStates?.time ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                                    }`}
                                >
                                    {localStates?.time ? localStates?.time : 'Select frequency'}
                                </p>

                                <img
                                    src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                    alt="Arrow Down Icon"
                                    className="object-contain"
                                />
                            </button>

                            <Menu
                                anchorEl={firstReviewDataAnchorEl}
                                open={Boolean(firstReviewDataAnchorEl)}
                                onClose={() => setFirstReviewDataAnchorEl(null)}
                                sx={{
                                    '& .MuiPaper-root': {
                                        borderRadius: '4px',
                                        boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                    },
                                    '& .MuiList-root': {
                                        padding: 0,
                                    },
                                }}
                            >
                                <div className="flex flex-col min-w-[149px]">
                                    {['Days', 'Weeks', 'Months'].map((duration, index) => (
                                        <MenuItem
                                            key={index}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setLocalStates((prev) => ({
                                                    ...prev,
                                                    time: duration,
                                                }));
                                                setRiskAccept((prev) => ({
                                                    ...prev,
                                                    contingency_timeline: {
                                                        ...prev.contingency_timeline,
                                                        time: duration,
                                                    },
                                                }));
                                                setFirstReviewDataAnchorEl(null);
                                            }}
                                            sx={{
                                                color: '#64748B',
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                textTransform: 'capitalize',
                                                padding: '8px 16px',
                                            }}
                                        >
                                            {duration}
                                        </MenuItem>
                                    ))}
                                </div>
                            </Menu>
                            {error && localStates?.time === 0 && (
                                <span className="text-xs text-[#FF0000] font-normal mt-2">Please select an option</span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-2">
                    <p className="font-medium text-sm text-[#64748B]">Assign contingency plan to:</p>

                    <div>
                        <button
                            aria-controls={assignToAnchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={assignToAnchorEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setAssignToAnchorEl(event.currentTarget);
                            }}
                            className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                        >
                            <p
                                className={`font-normal text-sm ${
                                    riskAccept?.solution_owner ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                                }`}
                            >
                                {riskAccept?.solution_owner ? riskAccept?.solution_owner : 'Select owner'}
                            </p>

                            <img
                                src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                alt="Arrow Down Icon"
                                className="object-contain"
                            />
                        </button>

                        <Menu
                            anchorEl={assignToAnchorEl}
                            open={Boolean(assignToAnchorEl)}
                            onClose={() => setAssignToAnchorEl(null)}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow:
                                        '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                                },
                                '& .MuiList-root': {
                                    padding: 0,
                                },
                            }}
                        >
                            {' '}
                            <div className="!py-[5px] !px-3 flex flex-col gap-2.5">
                                <p className="border-b border-[#F1F5F9] !py-1 !px-3 text-[#64748B] font-medium text-xs">
                                    Search email
                                </p>

                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="w-full !py-2.5 !pr-2.5 !pl-8 !border !border-[#E2E8F0] h-7 text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                        value={search}
                                        onChange={(event) => {
                                            event.stopPropagation();
                                            setSearch(event.target.value);
                                        }}
                                    />

                                    <img
                                        src="/img/risk-assessment/ri_search-line-small.svg"
                                        alt="Search Icon"
                                        className="absolute object-contain top-1/2 -translate-y-1/2 left-[10px]"
                                    />
                                </div>
                            </div>
                            {filtered_employees?.length ? (
                                <div className="!p-2 flex flex-col min-w-[224px]">
                                    {filtered_employees?.map((category) => (
                                        <MenuItem
                                            key={category?.id}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setRiskAccept((prev) => ({
                                                    ...prev,
                                                    solution_owner: category?.email,
                                                }));
                                                setAssignToAnchorEl(null);
                                            }}
                                            sx={{
                                                color: '#64748B',
                                                fontWeight: 500,
                                                fontSize: '13px',
                                                padding: '6px 8px',
                                            }}
                                        >
                                            {category?.email}
                                        </MenuItem>
                                    ))}
                                </div>
                            ) : (
                                <p className="flex items-center justify-center my-4 text-sm text-[#64748B] font-medium">
                                    User not found
                                </p>
                            )}
                        </Menu>
                        {error && riskAccept?.solution_owner === '' && (
                            <span className="text-xs text-[#FF0000] font-normal mt-2">
                                Assign contigency plan to is required
                            </span>
                        )}
                    </div>
                </div>

                <div className="flex gap-4">
                    <div className="flex-1 flex flex-col gap-2">
                        <div className="flex items-center gap-1">
                            <p className="font-medium text-sm text-[#64748B]">Residual likelihood</p>

                            <CustomTooltip
                                title="Determine the probability of risk occurrence after applying mitigation controls."
                                placement="top"
                            >
                                <img
                                    src="/img/risk-assessment/help-circle.svg"
                                    alt="Help Icon"
                                    className="object-contain"
                                />
                            </CustomTooltip>
                        </div>

                        <div>
                            <button
                                aria-controls={residualLikelihoodAnchorEl ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={residualLikelihoodAnchorEl ? 'true' : undefined}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setResidualLikelihoodAnchorEl(event.currentTarget);
                                }}
                                className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                            >
                                <p
                                    className={`font-normal text-sm ${
                                        residual_likelihood ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                                    }`}
                                >
                                    {residual_likelihood
                                        ? is_5x5
                                            ? risk_settings?.rows_5x5[
                                                  residualLikelihoodOrImpact?.find(
                                                      (item) => item?.value === residual_likelihood
                                                  )?.value - 1
                                              ]
                                                ? risk_settings?.rows_5x5[
                                                      residualLikelihoodOrImpact?.find(
                                                          (item) => item?.value === residual_likelihood
                                                      )?.value - 1
                                                  ]
                                                : residualLikelihoodOrImpact?.find(
                                                      (item) => item?.value === residual_likelihood
                                                  )?.name
                                            : risk_settings?.rows_3x3[
                                                  residualLikelihoodOrImpact?.find(
                                                      (item) => item?.value === residual_likelihood
                                                  )?.value - 1
                                              ]
                                            ? risk_settings?.rows_3x3[
                                                  residualLikelihoodOrImpact?.find(
                                                      (item) => item?.value === residual_likelihood
                                                  )?.value - 1
                                              ]
                                            : residualLikelihoodOrImpact?.find(
                                                  (item) => item?.value === residual_likelihood
                                              )?.name
                                        : 'Select residual likelihood'}
                                </p>

                                <img
                                    src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                    alt="Arrow Down Icon"
                                    className="object-contain"
                                />
                            </button>

                            <Menu
                                anchorEl={residualLikelihoodAnchorEl}
                                open={Boolean(residualLikelihoodAnchorEl)}
                                onClose={() => setResidualLikelihoodAnchorEl(null)}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    '& .MuiPaper-root': {
                                        borderRadius: '4px',
                                        boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                    },
                                    '& .MuiList-root': {
                                        padding: 0,
                                    },
                                }}
                            >
                                <div className="!p-2 flex flex-col min-w-[421px]">
                                    {residualLikelihoodOrImpact.map((level, index) => (
                                        <MenuItem
                                            key={index}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setResidualLikelihood(level.value);
                                                setResidualLikelihoodAnchorEl(null);
                                            }}
                                            sx={{
                                                color: '#64748B',
                                                fontWeight: 500,
                                                fontSize: '13px',
                                                textTransform: 'capitalize',
                                                padding: '6px 8px',
                                            }}
                                        >
                                            {is_5x5
                                                ? risk_settings?.rows_5x5[level?.value - 1]
                                                    ? risk_settings?.rows_5x5[level?.value - 1]
                                                    : level?.name
                                                : risk_settings?.rows_3x3[level?.value - 1]
                                                ? risk_settings?.rows_3x3[level?.value - 1]
                                                : level?.name}
                                        </MenuItem>
                                    ))}
                                </div>
                            </Menu>
                        </div>
                        {error && residual_likelihood === '' && (
                            <span className="text-xs text-[#FF0000] font-normal mt-2">
                                Residual Likelihood is required
                            </span>
                        )}
                    </div>

                    <div className="flex-1 flex flex-col gap-2">
                        <div className="flex items-center gap-1">
                            <p className="font-medium text-sm text-[#64748B]">Residual impact</p>

                            <CustomTooltip
                                title="Evaluate the potential consequence of the risk after mitigation controls are in place."
                                placement="top"
                            >
                                <img
                                    src="/img/risk-assessment/help-circle.svg"
                                    alt="Help Icon"
                                    className="object-contain"
                                />
                            </CustomTooltip>
                        </div>

                        <div>
                            <button
                                aria-controls={residualImpactAnchorEl ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={residualImpactAnchorEl ? 'true' : undefined}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setResidualImpactAnchorEl(event.currentTarget);
                                }}
                                className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                            >
                                <p
                                    className={`font-normal text-sm ${
                                        residual_impact ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                                    }`}
                                >
                                    {residual_impact
                                        ? is_5x5
                                            ? risk_settings?.columns_5x5[
                                                  residualLikelihoodOrImpact?.find(
                                                      (item) => item?.value === residual_impact
                                                  )?.value - 1
                                              ]
                                                ? risk_settings?.columns_5x5[
                                                      residualLikelihoodOrImpact?.find(
                                                          (item) => item?.value === residual_impact
                                                      )?.value - 1
                                                  ]
                                                : residualLikelihoodOrImpact?.find(
                                                      (item) => item?.value === residual_impact
                                                  )?.name
                                            : risk_settings?.columns_3x3[
                                                  residualLikelihoodOrImpact?.find(
                                                      (item) => item?.value === residual_impact
                                                  )?.value - 1
                                              ]
                                            ? risk_settings?.columns_3x3[
                                                  residualLikelihoodOrImpact?.find(
                                                      (item) => item?.value === residual_impact
                                                  )?.value - 1
                                              ]
                                            : residualLikelihoodOrImpact?.find(
                                                  (item) => item?.value === residual_impact
                                              )?.name
                                        : 'Select residual impact'}
                                </p>

                                <img
                                    src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                    alt="Arrow Down Icon"
                                    className="object-contain"
                                />
                            </button>

                            <Menu
                                anchorEl={residualImpactAnchorEl}
                                open={Boolean(residualImpactAnchorEl)}
                                onClose={() => setResidualImpactAnchorEl(null)}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    '& .MuiPaper-root': {
                                        borderRadius: '4px',
                                        boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                    },
                                    '& .MuiList-root': {
                                        padding: 0,
                                    },
                                }}
                            >
                                <div className="!p-2 flex flex-col min-w-[421px]">
                                    {residualLikelihoodOrImpact.map((level, index) => (
                                        <MenuItem
                                            key={index}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setResidualImpact(level?.value);
                                                setResidualImpactAnchorEl(null);
                                            }}
                                            sx={{
                                                color: '#64748B',
                                                fontWeight: 500,
                                                fontSize: '13px',
                                                textTransform: 'capitalize',
                                                padding: '6px 8px',
                                            }}
                                        >
                                            {is_5x5
                                                ? risk_settings?.columns_5x5[level?.value - 1]
                                                    ? risk_settings?.columns_5x5[level?.value - 1]
                                                    : level?.name
                                                : risk_settings?.columns_3x3[level?.value - 1]
                                                ? risk_settings?.columns_3x3[level?.value - 1]
                                                : level?.name}
                                        </MenuItem>
                                    ))}
                                </div>
                            </Menu>
                        </div>
                        {error && residual_impact === '' && (
                            <span className="text-xs text-[#FF0000] font-normal mt-2">Residual Impact is required</span>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AcceptForm;
